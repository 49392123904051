<template>
  <section class="eLearningMine container">
    <div class="eLearningMine__cardContainer">
      <ELearningYoutubeCard
        v-for="(mineItem, index) in mineLists"
        :key="index"
        :item="mineItem"
      />
      <hr class="eLearningMine__hr" />
    </div>
  </section>
</template>

<script>
import ELearningYoutubeCard from '@/components/ELearning/ELearningYoutubeCard.vue'

import { apiGetELearning } from '@/api/webAPI'

export default {
  components: {
    ELearningYoutubeCard
  },
  data() {
    return {
      mineLists: []
    }
  },
  async created() {
    const eLearningResponse = await apiGetELearning('mine')
    this.mineLists = eLearningResponse.data.data

    // 給banner過濾年份用
    // this.$store.commit('setELearning', this.mineLists)
  }
}
</script>

<style lang="scss">
.eLearningMine {
  padding: 4.9rem 0;
  overflow: hidden;

  &__cardContainer {
    display: flex;
    flex-wrap: wrap;
    margin: -1.8rem;
  }

  &__hr {
    border: none;
    border-bottom: 1px dashed #d0a805;
    width: 100%;
    margin: 1.2rem 0 10rem 0;
  }
}

@media (max-width: 768px) {
  .eLearningMine {
    padding: 4.9rem 2rem;
  }
}
</style>
